import './styles.scss';
import React, {memo, useState} from 'react';
// import parse from 'html-react-parser';
// import {Link, navigate} from "gatsby";
// import {
//     greetingsLogo,
//     greetingsMenu,
//     greetingsBack
// } from "../../images";

interface MyComponentProps {
    icon: string,
    title: string,
    text: string,
    height: string,
    checkImageLoad: () => void
}

const GreetingsBanner = ({ icon, title, text, height, checkImageLoad } : MyComponentProps) => {

    return (
        <>
            {/* <img src={icon} alt="" className="greetingsBigimage mb16" /> */}
            <div className="greetingsBigimage mb16">
                <span className={`pbi${height}`}></span>
                <img src={icon} onLoad={checkImageLoad} alt="" />
            </div>
            <h2 className="greetingsSubtitle small mb8">{title}</h2>
            <p className="greetingsText tal fs18">{text}</p>
        </>
    )
}

export default memo(GreetingsBanner);
