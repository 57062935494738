import './styles.scss';
import React, {memo, useState} from 'react';
import {
    greetingsPlus
} from "../../images";

interface MyComponentProps {
    data: {
        id: number,
        question: string,
        answer: string
    }
}

const GreetingsFaqItem = ({ data } : MyComponentProps) => {

    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive)
    }

    return (
        <>
            <div key={data.id} className="greetingsFaqItem" onClick={() => handleClick()}>
                <div className={`greetingsFaqHeading ${isActive ? 'active' : ''}`}>
                    <span>{data.question}</span>
                    <img src={greetingsPlus} alt="" />
                </div>
                <div className={`greetingsFaqText ${isActive ? 'active' : ''}`}>
                    <p>{data.answer}</p>
                </div>
            </div>
        </>
    )
}

export default memo(GreetingsFaqItem);
