import '../styles/global.scss';
import '../styles/pages/greetings.scss';

import {HeadFC} from "gatsby";

// import Slider from "react-slick";
import React, {useEffect, useState, useMemo} from 'react';
// import parse from 'html-react-parser';
// import {Link, navigate} from "gatsby";

import {
    logoOnBoard,
    
} from "../images";
import useAnalytics from "../hooks/useAnalytics";
import GreetingsPageItem from '../components/GreetingsPageItem';


type Props = {
    id: string,
}

const GreetingsPage = ({id}: Props) => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();
    const pages = [
        {
            id: 1,
            type: '1',
            page: 'start',
            next: '/greetings/gender',
        },
        {
            id: 2,
            type: '1',
            page: 'gender',
            next: '/greetings/lets-continue',
        },
        {
            id: 3,
            type: '1',
            page: 'lets-continue',
            next: '/greetings/choose-area',
        },
        {
            id: 4,
            type: '1',
            page: 'choose-area',
            next: '/greetings/thanks',
        },
        {
            id: 5,
            type: '1',
            page: 'thanks',
            next: '/greetings/who-inspire',
        },
        {
            id: 6,
            type: '1',
            page: 'who-inspire',
            next: '/greetings/person-type',
        },
        {
            id: 7,
            type: '1',
            page: 'person-type',
            next: '/greetings/detail-oriented',
        },
        {
            id: 8,
            type: '1',
            page: 'detail-oriented',
            next: '/greetings/what-you-want',
        },
        {
            id: 8,
            type: '1',
            page: 'big-picture',
            next: '/greetings/what-you-want',
        },
        {
            id: 7,
            type: '1',
            page: 'what-you-want',
            next: '/greetings/doubt-youself',
        },
        {
            id: 10,
            type: '1',
            page: 'doubt-youself',
            next: '/greetings/',
        },
        {
            id: 9,
            type: '1',
            page: 'mistakes',
            next: '/greetings/what-describes',
        },
        {
            id: 9,
            type: '1',
            page: 'no-mistakes',
            next: '/greetings/what-describes',
        },
        {
            id: 11,
            type: '1',
            page: 'what-describes',
            next: '/greetings/quick-decision',
        },
        {
            id: 14,
            type: '1',
            page: 'quick-decision',
            next: '/greetings/',
        },
        {
            id: 13,
            type: '1',
            page: 'hesitation',
            next: '/greetings/face-challange',
        },
        {
            id: 13,
            type: '1',
            page: 'no-hesitation',
            next: '/greetings/face-challange',
        },
        {
            id: 12,
            type: '1',
            page: 'face-challange',
            next: '/greetings/nice-job',
        },
        {
            id: 15,
            type: '1',
            page: 'nice-job',
            next: '/greetings/what-consider',
        },
        {
            id: 16,
            type: '1',
            page: 'what-consider',
            next: '/greetings/yay-team',
        },
        {
            id: 17,
            type: '1',
            page: 'yay-extrovert',
            next: '/greetings/better-performance',
        },
        {
            id: 17,
            type: '1',
            page: 'yay-introvert',
            next: '/greetings/better-performance',
        },
        {
            id: 17,
            type: '1',
            page: 'yay-both',
            next: '/greetings/better-performance',
        },
        {
            id: 19,
            type: '1',
            page: 'better-performance',
            next: '/greetings/friendly-push',
        },
        {
            id: 18,
            type: '1',
            page: 'friendly-push',
            next: '/greetings/better-performance',
        },
        {
            id: 18,
            type: '1',
            page: 'no-fp',
            next: '/greetings/need-to-boost',
        },
        {
            id: 18,
            type: '1',
            page: 'fp',
            next: '/greetings/need-to-boost',
        },
        {
            id: 18,
            type: '1',
            page: 'fp-motivator',
            next: '/greetings/need-to-boost',
        },
        {
            id: 20,
            type: '1',
            page: 'need-to-boost',
            next: '/greetings/tend-to-lose',
        },
        {
            id: 21,
            type: '1',
            page: 'tend-to-lose',
            next: '/greetings/next',
        },
        {
            id: 22,
            type: '1',
            page: 'great-for-you',
            next: '/greetings/sex-life',
        },
        {
            id: 22,
            type: '1',
            page: 'your-boundaries',
            next: '/greetings/sex-life',
        },
        {
            id: 25,
            type: '1',
            page: 'sex-life',
            next: '/greetings/body-and-desires',
        },
        {
            id: 24,
            type: '1',
            page: 'body-and-desires',
            next: '/greetings/you-rock',
        },
        {
            id: 23,
            type: '1',
            page: 'you-rock',
            next: '/greetings/succeed',
        },
        {
            id: 26,
            type: '1',
            page: 'succeed',
            next: '/greetings/focusing',
        },
        {
            id: 26,
            type: '1',
            page: 'beneficial',
            next: '/greetings/job',
        },
        {
            id: 26,
            type: '1',
            page: 'focusing',
            next: '/greetings/job',
        },
        {
            id: 26,
            type: '1',
            page: 'job',
            next: '/greetings/work-life-balance',
        },
        {
            id: 27,
            type: '1',
            page: 'work-life-balance',
            next: '/greetings/next',
        },
        {
            id: 27,
            type: '1',
            page: 'healthy-wlb',
            next: '/greetings/capable',
        },
        {
            id: 27,
            type: '1',
            page: 'glad-to-hear',
            next: '/greetings/capable',
        },
        {
            id: 27,
            type: '1',
            page: 'capable',
            next: '/greetings/finances',
        },
        {
            id: 27,
            type: '1',
            page: 'finances',
            next: '/greetings/often-worry',
        },
        {
            id: 27,
            type: '1',
            page: 'often-worry',
            next: '/greetings/next',
        },
        {
            id: 27,
            type: '1',
            page: 'confidence',
            next: '/greetings/compare-yourself',
        },
        {
            id: 27,
            type: '1',
            page: 'very-common',
            next: '/greetings/compare-yourself',
        },
        {
            id: 27,
            type: '1',
            page: 'compare-yourself',
            next: '/greetings/inspire-us',
        },
        {
            id: 28,
            type: '1',
            page: 'inspire-us',
            next: '/greetings/book-1',
        },
        {
            id: 29,
            type: '1',
            page: 'book-1',
            next: '/greetings/book-2',
        },
        {
            id: 30,
            type: '1',
            page: 'book-2',
            next: '/greetings/book-3',
        },
        {
            id: 31,
            type: '1',
            page: 'book-3',
            next: '/greetings/book-4',
        },
        {
            id: 32,
            type: '1',
            page: 'book-4',
            next: '/greetings/great-choise',
        },
        {
            id: 34,
            type: '1',
            page: 'great-choise',
            next: '/greetings/self-growth',
        },
        {
            id: 35,
            type: '1',
            page: 'self-growth',
            next: '/greetings/set-goal',
        },
        {
            id: 36,
            type: '1',
            page: 'set-goal',
            next: '/greetings/learning-experience',
        },
        {
            id: 37,
            type: '1',
            page: 'learning-experience',
            next: '/greetings/email',
        },
        {
            id: 38,
            type: '1',
            page: 'email',
            next: '/greetings/plan-is-ready',
        },
        {
            id: 39,
            type: '1',
            page: 'plan-is-ready',
            next: '/greetings/choose-plan',
        },
        {
            id: 40,
            type: '1',
            page: 'choose-plan',
            next: '/greetings/payment',
        },
        {
            id: 40,
            type: '1',
            page: 'payment',
            next: '/greetings/payment',
        },
    ]

    useEffect(() => {
        initAnalytics();
        console.log('Trigger event');
    }, []);

    const renderPage = () => {
        return pages.map((data) => {
            return (id === data.page) && <GreetingsPageItem key={data.id} children={data} />
        })
    }

    // const renderPages = () => {
    //     switch(id) {
    //         case 'main':
    //             return <div className="onBoardMain">
                    
    //             </div>;
    //         default:
    //             return <div>This page doesn`t exist</div>;
    //     }
    // }
    const css = `
        body {
            background-color: #FFF8F5;
        }
    `;

    return (
        <>
            <style>{css}</style>
            <div className="greetings">
                <div className="greetingsContainer">
                    {renderPage()}
                </div>
            </div>
        </>
    )
}

export default GreetingsPage;

export const Head: HeadFC = () => (
    <>
        <title>Greetings - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
