import './styles.scss';
import React, {memo, useState, useEffect} from 'react';
import parse from 'html-react-parser';
import {Link, navigate} from "gatsby";
import {toast, ToastContainer} from "react-toastify";
import {
    ArrowRight,
    GreetingsUser1,
    GreetingsUser2,
    GreetingsUser3,
    GreetingsUser4,
    Highfive,
    Driver,
    Person1,
    Person2,
    Person3,
    Person4,
    Emoji1,
    Emoji2,
    Emoji3,
    Emoji4,
    Emoji5,
    greetingsBanner1,
    greetingsBanner2,
    greetingsBanner3,
    greetingsBanner4,
    greetingsBanner5,
    greetingsBanner6,
    greetingsBanner7,
    greetingsBanner8,
    greetingsBanner9,
    greetingsBanner10,
    greetingsBanner11,
    greetingsBanner12,
    greetingsBanner13,
    greetingsBanner14,
    greetingsBanner15,
    greetingsBanner16,
    greetingsBanner17,
    greetingsBanner20,
    greetingsBanner21,
    greetingsBanner22,
    greetingsBanner23,
    greetingsBook1,
    greetingsBook2,
    greetingsBook3,
    greetingsBook4,
    greetingsNo,
    greetingsYes,
    greetingsStar,
    greetingsDone,
    greetingsEnvelope,
    greetingsApple,
    greetingsAndroid,
    greetingsGraph,
    greetingsMind,
    greetingsSecure,
    advaStar,
    greetingsPlus,
    greetingsComment1,
    greetingsGplay,
    greetingsAppstore,
    greetingsPay1,
    greetingsPay2,
    greetingsPay3,
    greetingsPay4,
    greetingsPay5,
    greetingsPay6,
    greetingsPay7,
    greetingsPay8,
    greetingsLock,
    greetingsApay,
    greetingsSafe1,
    greetingsSafe2,
    greetingsSafe3,
    greetingsSafe4,
} from "../../images";
import GreetingsHeader from '../GreetingsHeader';
import GreetingsProgress from '../GreetingsProgress';
import useAnalytics from '../../hooks/useAnalytics';
import GreetingsFaqItem from '../GreetingsFaqItem';
import GreetingsBanner from '../GreetingsBanner';

interface MyComponentProps {
    children: {
        id: number,
        type: string,
        page: string,
        next: string
    }
}

let interval1: NodeJS.Timeout | undefined;
let interval2: NodeJS.Timeout | undefined;
let interval3: NodeJS.Timeout | undefined;
let interval4: NodeJS.Timeout | undefined;

const GreetingsPageItem = ({ children } : MyComponentProps) => {

    const bannersList = [
        {
            id: 1,
            icon: greetingsBanner1,
            title: "Having big-picture thinking is a helpful trait for solving problems.",
            text: "Indeed, there’s no right or wrong way, and seeing details is just as important. So we’ll consider this in creating your plan to help you improve your ability to see both the scope and details clearly!",
            height: '48',
        },
        {
            id: 2,
            icon: greetingsBanner2,
            title: "Having detail-oriented thinking is a helpful trait for accomplishing your tasks practically flawlessly.",
            text: "Indeed, there’s no right or wrong way, and seeing the big picture is just as important. So we’ll consider this in creating your plan to help you improve your ability to see both the scope and details clearly!",
            height: '48',
        },
        {
            id: 3,
            icon: greetingsBanner3,
            title: "We’re glad you feel this way!",
            text: "It’s crucial to see your mistakes as space for growth, not as a source of insecurity. And with your growth plan, you’ll learn how to make the best out of them in the future.",
            height: '48',
        },
        {
            id: 4,
            icon: greetingsBanner4,
            title: "If you don't make mistakes, you don't make anything. So, doubting yourself and your skills is normal if you make a mistake, buddy!",
            text: "However, working out this issue will help you achieve your goals more seamlessly and efficiently. And your growth plan will target this as well.",
            height: '48',
        },
        {
            id: 5,
            icon: greetingsBanner5,
            title: "Way to go!",
            text: "We admire your decisiveness, as it’s an essential trait for becoming successful.",
            height: '48',
        },
        {
            id: 6,
            icon: greetingsBanner5,
            title: "Hesitation before making a decision is common, yet it can be somewhat limiting on a daily basis ",
            text: "We will help you sort this out!",
            height: '48',
        },
        {
            id: 7,
            icon: greetingsBanner7,
            title: "Yay for the extrovert team!",
            text: "People who identify themselves as extroverts need a unique approach to be happy. We take everything you tell us into account, so we craft your growth plan accordingly!",
            height: '48',
        },
        {
            id: 8,
            icon: greetingsBanner8,
            title: "Yay for the introvert team!",
            text: "People who identify themselves as introverts need a unique approach to being happy. We take everything you tell us into account, so we craft your growth plan accordingly!",
            height: '48',
        },
        {
            id: 9,
            icon: greetingsBanner9,
            title: "Yay for the ambivert team!",
            text: "People who identify themselves as extroverts and introverts depending on a situation are called ambiverts. And these folks need a unique approach to being happy.",
            height: '48',
        },
        {
            id: 10,
            icon: greetingsBanner10,
            title: "A friendly push is not for everyone, and that’s perfectly okay.",
            text: "We’ll consider how you feel about it for creating your growth plan.",
            height: '48',
        },
        {
            id: 11,
            icon: greetingsBanner11,
            title: "A friendly push is not for everyone, and that’s perfectly okay.",
            text: "People who identify themselves as introverts need a unique approach to being happy. We take everything you tell us into account, so we craft your growth plan accordingly!",
            height: '48',
        },
        {
            id: 12,
            icon: greetingsBanner11,
            title: "A friendly push here and there can surely be an excellent motivator.",
            text: "We’ll consider how you feel about it for creating your growth plan.",
            height: '48',
        },
        {
            id: 13,
            icon: greetingsBanner13,
            title: "A healthy work-life balance is crucial for staying resourceful and achieving your goals.",
            text: "Your growth plan will help you balance out all life spheres to feel both fulfilled and energetic.",
            height: '48',
        },
        {
            id: 14,
            icon: greetingsBanner14,
            title: "We’re glad to hear that!",
            text: "A healthy work-life balance does wonders for staying resourceful. And that is one of the key components of being successful.",
            height: '48',
        },
        {
            id: 15,
            icon: greetingsBanner15,
            title: "We admire your confidence!",
            text: "It’s paramount when it comes to being successful. And we’ll help you boost it to even better heights.",
            height: '48',
        },
        {
            id: 16,
            icon: greetingsBanner16,
            title: "It’s very common to worry that you’re not good enough.",
            text: "Yet overcoming this obstacle will help you get closer to your success. We’ll consider this while crafting your plan to give you the best confidence insights!",
            height: '48',
        },
        {
            id: 17,
            icon: greetingsBanner17,
            title: "Great for you!",
            text: "Your boundaries are important and valid, and we’re glad you hold onto them.",
            height: '48',
        },
        {
            id: 18,
            icon: greetingsBanner12,
            title: "No matter what, your boundaries are important, and it can hurt to lose them.",
            text: "There’s always room for improvement, though! We’ll help you work on it with your growth plan.",
            height: '48',
        },
        {
            id: 19,
            icon: greetingsBanner6,
            title: "You rock!",
            text: "You’ll breeze through your goals because you’re so determined! Now we’ll assess your personality patterns to better tailor the growth plan.",
            height: '48',
        },
        {
            id: 20,
            icon: greetingsBanner20,
            title: "Focusing on the good parts is an excellent source of motivation.",
            text: "Your growth plan will help you maintain that positive outlook while seeing room for potential on your road to success.",
            height: '48',
        },
        {
            id: 21,
            icon: greetingsBanner21,
            title: "Your sincerity inspires us!",
            text: "Knowing your patterns is crucial to crafting a precise growth plan for you. We also wonder about your reading preferences! It will help us recommend the most relevant content to you.",
            height: '48',
        },
        {
            id: 22,
            icon: greetingsBanner22,
            title: "Great choices, ace! We’re almost done.",
            text: "Thanks for your impressive sincerity. We’ve almost done setting up your roadmap. A little patience, fellow!",
            height: '100',
        },
        {
            id: 23,
            icon: greetingsBanner23,
            title: "It’s beneficial to see room for potential, but it’s also useful to “pat yourself on the back” for what you did well.",
            text: "Your growth plan will help you look for improvement opportunities and celebrate the good for staying motivated on your road to success.",
            height: '48',
        },
    ];

    const [running1, setRunning1] = useState(false);
    const [progress1, setProgress1] = useState(0);
    const [modalStatus1, setModalStatus1] = useState(false);

    const [running2, setRunning2] = useState(false);
    const [progress2, setProgress2] = useState(0);
    const [modalStatus2, setModalStatus2] = useState(false);

    const [running3, setRunning3] = useState(false);
    const [progress3, setProgress3] = useState(0);
    const [modalStatus3, setModalStatus3] = useState(false);

    const [running4, setRunning4] = useState(false);
    const [progress4, setProgress4] = useState(0);

    useEffect(() => {
        if(children.page === 'learning-experience'){
            setRunning1(true);
        }
    }, [children.page]);

    useEffect(() => {
        if (running1) {
            interval1 = setInterval(() => {
                setProgress1((prev) => prev + 1);
            }, 25);
        } else {
            clearInterval(interval1);
        }
    }, [running1]);
    useEffect(() => {
        if (running2) {
            interval2 = setInterval(() => {
                setProgress2((prev) => prev + 1);
            }, 25);
        } else {
            clearInterval(interval2);
        }
    }, [running2]);
    useEffect(() => {
        if (running3) {
            interval3 = setInterval(() => {
                setProgress3((prev) => prev + 1);
            }, 25);
        } else {
            clearInterval(interval3);
        }
    }, [running3]);
    useEffect(() => {
        if (running4) {
            interval4 = setInterval(() => {
                setProgress4((prev) => prev + 1);
            }, 25);
        } else {
            clearInterval(interval4);
        }
    }, [running4]);

    useEffect(() => {
        if (progress1 === 49) {
            clearInterval(interval1);
            setModalStatus1(true);
            setRunning1(false);
        }
        if(progress1 === 100){
            clearInterval(interval1);
            setRunning1(false);
            setRunning2(true);
        }
    }, [progress1]);

    useEffect(() => {
        if (progress2 === 76) {
            clearInterval(interval2);
            setModalStatus2(true);
            setRunning2(false);
        }
        if(progress2 === 100){
            clearInterval(interval2);
            setRunning2(false);
            setRunning3(true);
        }
    }, [progress2]);

    useEffect(() => {
        if (progress3 === 38) {
            clearInterval(interval3);
            setModalStatus3(true);
            setRunning3(false);
        }
        if(progress3 === 100){
            clearInterval(interval3);
            setRunning3(false);
            setRunning4(true);
        }
    }, [progress3]);
    
    useEffect(() => {
        if(progress4 === 100){
            clearInterval(interval4);
            setRunning4(false);
            setNextBtnStatus(false);
        }
    }, [progress4]);

    const [nextBtnStatus, setNextBtnStatus] = useState(true);
    const [message, setMessage] = useState('0');
    const [messageSpec, setMessageSpec] = useState('0');
    const [checkedAreaCB, setCheckedAreaCB] = useState<string[]>([]);
    const [checkedInsipeCB, setCheckedInsipeCB] = useState<string[]>([]);
    const [checkedSeflGrowthCB, setCheckedSeflGrowthCB] = useState<string[]>([]);
    const {initAnalytics, saveEmail, saveSubType, saveTrialPrice} = useAnalytics();
    const [email, setEmail] = useState('');
    const [privacyStatus, setPrivacyStatus] = useState(false);
    const [blockHeight, setBlockHeight] = useState<number>(window.innerHeight);
    const [nextLink, setNextLink] = useState('');
    const [activeTarif, setActiveTarif] = useState('2');
    const [currentDate, setCurrentDate] = useState('');
    const [dateIn15Days, setDateIn15Days] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const renderBanner = (value:number) => {
        return bannersList.map((data) => {
            if(data.id === value){
                return <GreetingsBanner key={data.id} icon={data.icon} title={data.title} text={data.text} height={data.height} checkImageLoad={handleImageLoad} />
            }
        });
    }


    const renderPrice = () => {
        switch(activeTarif) {
            case '1':
                return <div className="greetingsPaymentPrice">
                    <div className="greetingsPaymentOld">16<span>.00</span></div>
                    <div className="greetingsPaymentNew">10<span>.72</span> USD</div>
                </div>;
            case '2':
                return <div className="greetingsPaymentPrice">
                    <div className="greetingsPaymentOld">29<span>.11</span></div>
                    <div className="greetingsPaymentNew">19<span>.50</span> USD</div>
                </div>;
            case '3':
                return <div className="greetingsPaymentPrice">
                    <div className="greetingsPaymentOld">50<span>.95</span></div>
                    <div className="greetingsPaymentNew">34<span>.14</span> USD</div>
                </div>;
            default:
                return <div>This page doesn`t exist</div>;
        }
        return <div className="greetingsPaymentPrice">
            <div className="greetingsPaymentOld">35<span>.80</span></div>
            <div className="greetingsPaymentNew">23<span>.99</span> USD</div>
        </div>
    }

    const renderRenewPrice = () => {
        switch(activeTarif) {
            case '1':
                return <p className="greetingsPlanDescr">We've automatically applied the discount to your first subscription price. Please note that your subscription will be automatically renewed at the full price of $26.92 at the end of the chosen subscription term. If you want to manage your subscription, you may do so via your personal account. </p>;
            case '2':
                return <p className="greetingsPlanDescr">We've automatically applied the discount to your first subscription price. Please note that your subscription will be automatically renewed at the full price of $48.96 at the end of the chosen subscription term. If you want to manage your subscription, you may do so via your personal account. </p>;
            case '3':
                return <p className="greetingsPlanDescr">We've automatically applied the discount to your first subscription price. Please note that your subscription will be automatically renewed at the full price of $85.69 at the end of the chosen subscription term. If you want to manage your subscription, you may do so via your personal account. </p>;
            default:
                return <div>This page doesn`t exist</div>;
        }
        return <div className="greetingsPaymentPrice">
            <div className="greetingsPaymentOld">35<span>.80</span></div>
            <div className="greetingsPaymentNew">23<span>.99</span> USD</div>
        </div>
    }

    const handleTarif = (value:string) => {
        localStorage.setItem('greeTarif', value);
        setActiveTarif(value);
    }

    const faqList = [
        {
            id: 1,
            question: 'Why do I need the FeelGrow app ?',
            answer: 'On the FeelGrow app, you will find 15-minute summaries of the world’s best nonfiction books. We’ve remastered all titles into short reads with key insights and tips. It will help you read more, learn something new every day, and decide what books you are most interested in reading.',
        },
        {
            id: 2,
            question: 'How do I get access to the app?',
            answer: 'After you fill in your email address, a mail with a temporary password and download links to App Store and Google Play will be in your inbox. Just install the app, and you will be able to access your account.',
        },
        {
            id: 3,
            question: 'How can I cancel my subscription?',
            answer: 'To cancel the subscription, click the "Unsubscribe" button in your account settings on the website. And don’t forget to cancel 24h before the end date! Otherwise, the payment for the next period will be made automatically.',
        }
    ];

    const handleResize = () => {
        setBlockHeight(window.innerHeight);
    };

    useEffect(() => {
        // Date
        const currentDateObj = new Date();
        const futureDateObj = new Date();
        futureDateObj.setDate(currentDateObj.getDate() + 15);

        const formatOptions = { month: 'long', day: 'numeric' };

        const formattedCurrentDate = currentDateObj.toLocaleDateString('en-US', formatOptions);
        const formattedDateIn15Days = futureDateObj.toLocaleDateString('en-US', formatOptions);

        setCurrentDate(formattedCurrentDate);
        setDateIn15Days(formattedDateIn15Days);

        const tarif = localStorage.getItem('greeTarif');
        if(tarif && children.page === 'choose-plan'){
            setActiveTarif(tarif);
        } else{
            setActiveTarif('2');
        }
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const validateEmail = () => {
        if (email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            saveEmail(email);
            localStorage.setItem('email', email);
            return true;
        } else{
            toast('Invalid e-mail address', {
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const valideOnKeyup = (value:string) => {
        setEmail(value);
        if(value.length > 0){
            setNextBtnStatus(false);
        } else{
            setNextBtnStatus(true);
        }
    }

    const submitEmailForm = () => {
        if(validateEmail()){
            if(!privacyStatus){
                toast('Please agree with our terms and privacy policy', {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else{
                navigate(children.next);
            }
        }
    }
    
    const handleModalNext1 = () => {
        setModalStatus1(false);
        setRunning1(true);
    }
    const handleModalNext2 = () => {
        setModalStatus2(false);
        setRunning2(true);
    }
    const handleModalNext3 = () => {
        setModalStatus3(false);
        setRunning3(true);
    }

    const handleCheckedCB = (array: string[], method:React.Dispatch<React.SetStateAction<string[]>>, value:string) => {
        if(array.includes(value)){
            method(prevArray => prevArray.filter(item => item !== value));
            (array.length === 1) && setNextBtnStatus(true);
        } else if(!array.includes(value)){
            method(prevArray => [...prevArray, value]);
            setNextBtnStatus(false);
        }
    };

    const handleMessage = (value:string) => {
        setMessage(value);
        setNextBtnStatus(false);
    }

    const handleMessageNext = (value:string) => {
        setNextLink(value);
        setNextBtnStatus(false);
    }

    const handleMessageNextSkip = (value:string) => {
        navigate(value);
    }

    const handleMessageSpec = (value:string) => {
        setMessageSpec(value);
        setNextBtnStatus(false);
    }

    const handleRadio = () => {
        setNextBtnStatus(false);
    }

    const handleRadioBanner = (value:string) => {
        setNextLink(value);
        setNextBtnStatus(false);
    }

    const handleNextBtn = () => {
        setNextBtnStatus(true);
        navigate(children.next);
    }

    const handleNextBtnBanner = () => {
        setNextBtnStatus(true);
        navigate(nextLink);
    }

    const renderPage = () => {
        switch(children.page) {
            case 'start':
                return <div className={`greetingsItem start ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type1" />
                    <h2 className="greetingsTitle mb24">Self-Growth Plan <br /> according to your age</h2>
                    <p className="greetingsText fw600 orange ttu">3 minute quiz</p>
                    <div className="greetingsImageAnswers">
                        <div className="greetingsImageAnswer" onClick={() => handleNextBtn()}>
                            <div className="greetingsImageAnswerIcon">
                                <img src={GreetingsUser1} alt="" />
                            </div>
                            <div className="greetingsImageAnswerText">
                                <span>Age: 18-24</span>
                                <img src={ArrowRight} alt="" />
                            </div>
                        </div>
                        <div className="greetingsImageAnswer" onClick={() => handleNextBtn()}>
                            <div className="greetingsImageAnswerIcon">
                                <img src={GreetingsUser2} alt="" />
                            </div>
                            <div className="greetingsImageAnswerText">
                                <span>Age: 25-34</span>
                                <img src={ArrowRight} alt="" />
                            </div>
                        </div>
                        <div className="greetingsImageAnswer" onClick={() => handleNextBtn()}>
                            <div className="greetingsImageAnswerIcon">
                                <img src={GreetingsUser3} alt="" />
                            </div>
                            <div className="greetingsImageAnswerText">
                                <span>Age: 35-44</span>
                                <img src={ArrowRight} alt="" />
                            </div>
                        </div>
                        <div className="greetingsImageAnswer" onClick={() => handleNextBtn()}>
                            <div className="greetingsImageAnswerIcon">
                                <img src={GreetingsUser4} onLoad={handleImageLoad} alt="" />
                            </div>
                            <div className="greetingsImageAnswerText">
                                <span>Age: 45+</span>
                                <img src={ArrowRight} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="greetingsFooter">
                        <p className="greetingsFooterText">
                        © AEER PLATFORM INC <br /> 8 THE GREEN STE A DOVER, DE 19901
                        </p>
                        <nav className="greetingsFooterNav">
                            <Link to="/terms">Terms of user</Link>
                            <Link to="/privacy">Privacy policy</Link>
                            <Link to="/cookie">Cookie policy</Link>
                            <Link to="/cancel">Unsubscribe</Link>
                        </nav>
                    </div>
                </div>;
            case 'gender':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="10" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsTitle">Select your gender</h2>
                    <div className="greetingsRadios mtauto">
                        <div className="greetingsRadio">
                            <input type="radio" name="gender" id="gender-1"
                                onChange={() => handleRadio()} />
                            <label htmlFor="gender-1">
                                {/* <img src={EmojiFace1} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">👨</span>
                                <span>Male</span>
                            </label>
                        </div>
                        <div className="greetingsRadio">
                            <input type="radio" name="gender" id="gender-2"
                                onChange={() => handleRadio()} />
                            <label htmlFor="gender-2">
                                {/* <img src={EmojiFace2} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">👩</span>
                                <span>Female</span>
                            </label>
                        </div>
                        <div className="greetingsRadio">
                            <input type="radio" name="gender" id="gender-3"
                                onChange={() => handleRadio()} />
                            <label htmlFor="gender-3">
                                {/* <img src={EmojiFace3} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">🧑‍🦲</span>
                                <span>Other</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'lets-continue':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="11" status="first" />
                    <p className="greetingsText fw600 orange ttu mb0">profile</p>
                    <div className="greetingsBigimage">
                        <span className="pbi100"></span>
                        <img src={Highfive} onLoad={handleImageLoad} alt="" />
                    </div>
                    <p className="greetingsText mb0 tal">Yay, glad you are here! We’ll help you with self-growth. Let’s continue so we can understand you better.</p>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'choose-area':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="12" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsTitle">Choose areas you’d like to elevate:</h2>
                    <p className="greetingsText">The choice won’t limit your experience.</p>
                    <div className="greetingsCheckboxes tobott gap">
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-12"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '12')} />
                            <label htmlFor="area-12">Entrepreneurship</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-13"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '13')} />
                            <label htmlFor="area-13">Business growth</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-14"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '14')} />
                            <label htmlFor="area-14">Communication</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-1"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '1')} />
                            <label htmlFor="area-1">Nutrition</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-2"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '2')} />
                            <label htmlFor="area-2">Emotions</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-3"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '3')} />
                            <label htmlFor="area-3">Habits</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-4"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '4')} />
                            <label htmlFor="area-4">Motivation</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-5"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '5')} />
                            <label htmlFor="area-5">Self-confidence</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-6"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '6')} />
                            <label htmlFor="area-6">Mindset</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-7"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '7')} />
                            <label htmlFor="area-7">Self-care</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-8"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '8')} />
                            <label htmlFor="area-8">Exercise</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-9"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '9')} />
                            <label htmlFor="area-9">Empathy</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-10"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '10')} />
                            <label htmlFor="area-10">Love & Relationships</label>
                        </div>
                        <div className="greetingsCheckbox">
                            <input type="checkbox" name="area[]" id="area-11"
                                onChange={() => handleCheckedCB(checkedAreaCB, setCheckedAreaCB,  '11')} />
                            <label htmlFor="area-11">Personal finances</label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            
            case 'thanks':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="13" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsSubtitle mb60">Thanks for being honest!</h2>
                    <p className="greetingsText tal mb16">Now, we’ll dive into your personality to have a better idea of you. It will help us build a personal approach to your growth plan.</p>
                    {/* <img src={Driver} alt="" className="greetingsBigimage" /> */}
                    <div className="greetingsBigimage">
                        <span className="pbi67"></span>
                        <img src={Driver} onLoad={handleImageLoad} alt="" />
                    </div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'who-inspire':
                return <div className="greetingsItem visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="14" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsSubtitle tac mb16">Whose life principles, success, and personality inspire you the most?</h2>
                    <div className="greetingsCheckboxes">
                        <div className="greetingsCheckboxImage">
                            <input type="checkbox" name="person[]" id="person-1"
                                onChange={() => handleCheckedCB(checkedInsipeCB, setCheckedInsipeCB,  '1')} />
                            <label htmlFor="person-1">
                                <span className="greetingsCheckboxImageIcon">
                                    <img src={Person1} alt="" />
                                </span>
                                <span className="greetingsCheckboxImageText">Steve Jobs</span>
                            </label>
                        </div>
                        <div className="greetingsCheckboxImage">
                            <input type="checkbox" name="person[]" id="person-2"
                                onChange={() => handleCheckedCB(checkedInsipeCB, setCheckedInsipeCB,  '2')} />
                            <label htmlFor="person-2">
                                <span className="greetingsCheckboxImageIcon">
                                    <img src={Person2} alt="" />
                                </span>
                                <span className="greetingsCheckboxImageText">Richard Branson</span>
                            </label>
                        </div>
                        <div className="greetingsCheckboxImage">
                            <input type="checkbox" name="person[]" id="person-3"
                                onChange={() => handleCheckedCB(checkedInsipeCB, setCheckedInsipeCB,  '3')} />
                            <label htmlFor="person-3">
                                <span className="greetingsCheckboxImageIcon">
                                    <img src={Person3} alt="" />
                                </span>
                                <span className="greetingsCheckboxImageText">LeBron James</span>
                            </label>
                        </div>
                        <div className="greetingsCheckboxImage">
                            <input type="checkbox" name="person[]" id="person-4"
                                onChange={() => handleCheckedCB(checkedInsipeCB, setCheckedInsipeCB,  '4')} />
                            <label htmlFor="person-4">
                                <span className="greetingsCheckboxImageIcon">
                                    <img src={Person4} alt="" />
                                </span>
                                <span className="greetingsCheckboxImageText">Oprah Winfrey</span>
                            </label>
                        </div>
                    </div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'person-type':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="15" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsSubtitle tac">Are you a big-picture or detail-oriented person?</h2>
                    <div className="greetingsRadios mtauto">
                        <div className="greetingsRadio">
                            <input type="radio" name="typepers" id="typepers-1"
                                onChange={() => handleRadioBanner('/greetings/big-picture')} />
                            <label htmlFor="typepers-1">
                                {/* <img src={EmojiFace1} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">🔭</span>
                                <span>Big-picture</span>
                            </label>
                        </div>
                        <div className="greetingsRadio">
                            <input type="radio" name="typepers" id="typepers-2"
                                onChange={() => handleRadioBanner('/greetings/detail-oriented')} />
                            <label htmlFor="typepers-2">
                                {/* <img src={EmojiFace2} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">🔍</span>
                                <span>Detail-oriented</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'big-picture':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="16" status="first" />
                    <p className="greetingsText fw600 orange ttu mb16">profile</p>
                    {renderBanner(1)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'detail-oriented':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="16" status="first" />
                    <p className="greetingsText fw600 orange ttu mb16">profile</p>
                    {renderBanner(2)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'what-you-want':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="17" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsSubtitle tac mb16">Do you always know what you want exactly?</h2>
                    <div className="greetingsEmojiBox tobott">
                        <div className={`greetingsMessage ${(message === '1') ? 'active' : ''}`}>
                            <span>Say no more!</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">✊</span>
                            <p>it’s hard to figure out your wishes. But a personal plan will help you determine, prioritize and achieve goals smoothly.</p>
                        </div>
                        <div className={`greetingsMessage ${(message === '2') ? 'active' : ''}`}>
                            <span>We understand!</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">🫂</span>
                            <p>We’ll consider the growth points you’ve picked to craft the most relevant plan for you needs.</p>
                        </div>
                        <div className={`greetingsMessage ${(message === '3') ? 'active' : ''}`}>
                            <span>Did you know that people spend 4.8 hours a day on their phones on average</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">⚡</span>
                            <p>So if you spend only 15 min daily using FeelGrow, you’ll get essential knowledge to crush your goals. *according to research by App Annie</p>
                        </div>
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-1"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="what-want-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                    
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-2"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="what-want-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-3"
                                    onChange={() => handleMessage('2')} />
                                <label htmlFor="what-want-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-4"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="what-want-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-5"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="what-want-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            
            case 'doubt-youself':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="18" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsSubtitle small tac mb16">I tend to doubt myself and my skills if I make a mistake</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We understand!</span>
                            <img src={MessageIcon1} alt="" />
                            <p>We’ll consider the growth points you’ve picked to craft the most relevant plan for you needs.</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-1"
                                    onChange={() => handleMessageNextSkip('/greetings/no-mistakes')} />
                                <label htmlFor="what-want-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-2"
                                    onChange={() => handleMessageNextSkip('/greetings/no-mistakes')} />
                                <label htmlFor="what-want-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-3"
                                    onChange={() => handleMessageNextSkip('/greetings/mistakes')} />
                                <label htmlFor="what-want-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-4"
                                    onChange={() => handleMessageNextSkip('/greetings/mistakes')} />
                                <label htmlFor="what-want-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="what-want" id="what-want-5"
                                    onChange={() => handleMessageNextSkip('/greetings/mistakes')} />
                                <label htmlFor="what-want-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'no-mistakes':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="19" status="first" />
                    <p className="greetingsText fw600 orange ttu mb16">profile</p>
                    {renderBanner(3)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'mistakes':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="20" status="first" />
                    <p className="greetingsText fw600 orange ttu mb16">profile</p>
                    {renderBanner(4)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'what-describes':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="22" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsSubtitle tac mb0 small">What describes you more?</h2>
                    <div className="greetingsRadios mtauto">
                        <div className={`greetingsMessage static ${(messageSpec === '1') ? 'active' : ''}`}>
                            <span>I came, I saw, I guided and inspire. </span>
                            <span className="img" role="img" aria-label="Smiley Emoji">🧗</span>
                            <p>The leader role is quite challenging. With only 15 min summary a day, you’ll learn how to elevate your leadership skills more efficiently.</p>
                        </div>
                        <div className={`greetingsMessage static ${(messageSpec === '2') ? 'active' : ''}`}>
                            <span>Self-awareness </span>
                            <span className="img" role="img" aria-label="Smiley Emoji">🙏</span>
                            <p>The follower can be a great team player and self-reliant participator. But you can change it. Star exploring yourself to mine your strengths and growth areas.</p>
                        </div>
                        <div className={`greetingsMessage static ${(messageSpec === '3') ? 'active' : ''}`}>
                            <span>“No” to traditional labels or roles.</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">😎</span>
                            <p>we’ll help you boost leadership and team player strengths with our library of 1200+ titles.</p>
                        </div>
                        <div className="greetingsRadio">
                            <input type="radio" name="what-describes" id="what-describes-1"
                                onChange={() => handleMessageSpec('1')} />
                            <label htmlFor="what-describes-1">
                                {/* <img src={greetingsRocket} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">🚀</span>
                                <span>A leader</span>
                            </label>
                        </div>
                        <div className="greetingsRadio">
                            <input type="radio" name="what-describes" id="what-describes-2"
                                onChange={() => handleMessageSpec('2')} />
                            <label htmlFor="what-describes-2">
                                {/* <img src={greetingsLapa} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">🐾</span>
                                <span>A follower</span>
                            </label>
                        </div>
                        <div className="greetingsRadio">
                            <input type="radio" name="what-describes" id="what-describes-3"
                                onChange={() => handleMessageSpec('3')} />
                            <label htmlFor="what-describes-3">
                                {/* <img src={greetingsGlasses} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">😎</span>
                                <span>A bit of both</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'quick-decision':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="24" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsSubtitle small tac mb16">I often find it challenging to make a decision quickly</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We understand!</span>
                            <img src={MessageIcon1} alt="" />
                            <p>We’ll consider the growth points you’ve picked to craft the most relevant plan for you needs.</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="quick-decision" id="quick-decision-1"
                                    onChange={() => handleMessageNextSkip('/greetings/no-hesitation')} />
                                <label htmlFor="quick-decision-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="quick-decision" id="quick-decision-2"
                                    onChange={() => handleMessageNextSkip('/greetings/no-hesitation')} />
                                <label htmlFor="quick-decision-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="quick-decision" id="quick-decision-3"
                                    onChange={() => handleMessageNextSkip('/greetings/hesitation')} />
                                <label htmlFor="quick-decision-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="quick-decision" id="quick-decision-4"
                                    onChange={() => handleMessageNextSkip('/greetings/hesitation')} />
                                <label htmlFor="quick-decision-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="quick-decision" id="quick-decision-5"
                                    onChange={() => handleMessageNextSkip('/greetings/hesitation')} />
                                <label htmlFor="quick-decision-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'no-hesitation':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="26" status="first" />
                    <p className="greetingsText fw600 orange ttu mb16">profile</p>
                    {renderBanner(5)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'hesitation':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="26" status="first" />
                    <p className="greetingsText fw600 orange ttu mb16">profile</p>
                    {renderBanner(6)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'face-challange':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="28" status="first" />
                    <p className="greetingsText fw600 orange ttu mb24">profile</p>
                    <h2 className="greetingsSubtitle small tac mb16">I usually feel that I lack the inner power to face challenges</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We understand!</span>
                            <img src={MessageIcon1} alt="" />
                            <p>We’ll consider the growth points you’ve picked to craft the most relevant plan for you needs.</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="face-challange" id="face-challange-1"
                                    onChange={() => handleMessageNextSkip('/greetings/nice-job')} />
                                <label htmlFor="face-challange-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="face-challange" id="face-challange-2"
                                    onChange={() => handleMessageNextSkip('/greetings/nice-job')} />
                                <label htmlFor="face-challange-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="face-challange" id="face-challange-3"
                                    onChange={() => handleMessageNextSkip('/greetings/nice-job')} />
                                <label htmlFor="face-challange-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="face-challange" id="face-challange-4"
                                    onChange={() => handleMessageNextSkip('/greetings/nice-job')} />
                                <label htmlFor="face-challange-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="face-challange" id="face-challange-5"
                                    onChange={() => handleMessageNextSkip('/greetings/nice-job')} />
                                <label htmlFor="face-challange-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'nice-job':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="36" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {/* {renderBanner(17)} */}
                    {/* <img src={greetingsBanner6} alt="" className="greetingsBigimage mb16" /> */}
                    <div className="greetingsBigimage mb16">
                        <span className="pbi48"></span>
                        <img src={greetingsBanner6} onLoad={handleImageLoad} alt="" />
                    </div>
                    <h2 className="greetingsTitle tal mb8">Nice job, fellow!</h2>
                    <p className="greetingsText tal fs18">Answer a few more questions so that we can better understand you and give really suitable tips. Let’s start!</p>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'what-consider':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="38" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">personality</p>
                    <h2 className="greetingsSubtitle tac small">What do you consider yourself?</h2>
                    <div className="greetingsRadios mtauto">
                        <div className="greetingsRadio">
                            <input type="radio" name="what-consider" id="what-consider-1"
                                onChange={() => handleRadioBanner('/greetings/yay-extrovert')} />
                            <label htmlFor="what-consider-1">
                                {/* <img src={greetingsSmile1} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">😄</span>
                                <span>Extrovert</span>
                            </label>
                        </div>
                        <div className="greetingsRadio">
                            <input type="radio" name="what-consider" id="what-consider-2"
                                onChange={() => handleRadioBanner('/greetings/yay-introvert')} />
                            <label htmlFor="what-consider-2">
                                {/* <img src={greetingsSmile2} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">😌</span>
                                <span>Introvert</span>
                            </label>
                        </div>
                        <div className="greetingsRadio">
                            <input type="radio" name="what-consider" id="what-consider-3"
                                onChange={() => handleRadioBanner('/greetings/yay-both')} />
                            <label htmlFor="what-consider-3">
                                {/* <img src={greetingsSmile3} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">🤔</span>
                                <span>Both</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'yay-extrovert':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="40" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(7)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'yay-introvert':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="40" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(8)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'yay-both':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="40" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(9)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            
            case 'better-performance':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="42" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">personality</p>
                    <h2 className="greetingsSubtitle small tac mb16">Do you associate regular workouts with better performance at work?</h2>
                    <div className="greetingsEmojiBox tobott">
                        <div className={`greetingsMessage ${(message === '1') ? 'active' : ''}`}>
                            <span>It works, we checked! </span>
                            <span className="img" role="img" aria-label="Smiley Emoji">🏋️‍♀️</span>
                            <p>Regular workout are crucial for stying resourceful and achieving your career goals. You growth plan will help you find a balance to feel energetic.</p>
                        </div>
                        <div className={`greetingsMessage ${(message === '2') ? 'active' : ''}`}>
                            <span>We can adjust your growth plan to help you explore more.</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">👐</span>
                            <p>A regular workout can actually do wonders for you concentration, memory, and stress levels!</p>
                        </div>
                        <div className={`greetingsMessage ${(message === '3') ? 'active' : ''}`}>
                            <span>We are on the same wavelength!</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">🌊</span>
                            <p>A healthy work-life balance, including regular workouts, does wonders for staying resourceful.</p>
                        </div>
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="better-performance" id="better-performance-1"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="better-performance-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="better-performance" id="better-performance-2"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="better-performance-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="better-performance" id="better-performance-3"
                                    onChange={() => handleMessage('2')} />
                                <label htmlFor="better-performance-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="better-performance" id="better-performance-4"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="better-performance-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="better-performance" id="better-performance-5"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="better-performance-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'friendly-push':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="44" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">personality</p>
                    <h2 className="greetingsSubtitle small tac mb16">Do you sometimes need a friendly push to keep moving forward?</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We understand!</span>
                            <img src={MessageIcon1} alt="" />
                            <p>We’ll consider the growth points you’ve picked to craft the most relevant plan for you needs.</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="fp" id="fp-1"
                                    onChange={() => handleMessageNextSkip('/greetings/no-fp')} />
                                <label htmlFor="fp-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="fp" id="fp-2"
                                    onChange={() => handleMessageNextSkip('/greetings/no-fp')} />
                                <label htmlFor="fp-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="fp" id="fp-3"
                                    onChange={() => handleMessageNextSkip('/greetings/fp')} />
                                <label htmlFor="fp-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="fp" id="fp-4"
                                    onChange={() => handleMessageNextSkip('/greetings/fp-motivator')} />
                                <label htmlFor="fp-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="fp" id="fp-5"
                                    onChange={() => handleMessageNextSkip('/greetings/fp-motivator')} />
                                <label htmlFor="fp-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'no-fp':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="46" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(10)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'fp':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="46" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(11)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'fp-motivator':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="46" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(12)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'need-to-boost':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="48" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">personality</p>
                    <h2 className="greetingsSubtitle small tac mb16">Do you feel the need to boost your time-management skills?</h2>
                    <div className="greetingsEmojiBox tobott">
                        <div className={`greetingsMessage ${(message === '1') ? 'active' : ''}`}>
                            <span>Kudos to you!</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">👏</span>
                            <p>Then let’s focus on other areas of your growth. But if someday you find this relevant, we can adjust your plan.</p>
                        </div>
                        <div className={`greetingsMessage ${(message === '2') ? 'active' : ''}`}>
                            <span>We all feel this way from time to time</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">🫂</span>
                            <p>And we can assist with boosting your time-management skill in any way you want to.</p>
                        </div>
                        <div className={`greetingsMessage ${(message === '3') ? 'active' : ''}`}>
                            <span>We love your ambition!</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">💪</span>
                            <p>93% of FeelGrow users also find time management essential to achieving life goals. And we'll help you with it.</p>
                        </div>
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-1"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="need-to-boost-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-2"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="need-to-boost-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-3"
                                    onChange={() => handleMessage('2')} />
                                <label htmlFor="need-to-boost-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-4"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="need-to-boost-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-5"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="need-to-boost-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'tend-to-lose':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="50" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">personality</p>
                    <h2 className="greetingsSubtitle small tac mb16">I often tend to lose my boundaries in relationships</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We can adjust your growth plan to help you explore more.</span>
                            <img src={MessageIcon2} alt="" />
                            <p>A regular workout can do wonders for your concentration, memory, and stress levels!</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-1"
                                    onChange={() => handleMessageNextSkip('/greetings/great-for-you')} />
                                <label htmlFor="tend-to-lose-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-2"
                                    onChange={() => handleMessageNextSkip('/greetings/great-for-you')} />
                                <label htmlFor="tend-to-lose-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-3"
                                    onChange={() => handleMessageNextSkip('/greetings/your-boundaries')} />
                                <label htmlFor="tend-to-lose-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-4"
                                    onChange={() => handleMessageNextSkip('/greetings/your-boundaries')} />
                                <label htmlFor="tend-to-lose-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-5"
                                    onChange={() => handleMessageNextSkip('/greetings/your-boundaries')} />
                                <label htmlFor="tend-to-lose-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'great-for-you':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="52" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(17)}
                    {/* <img src={greetingsBanner7} alt="" className="greetingsBigimage mb16" />
                    <h2 className="greetingsSubtitle small mb8">No matter what, your boundaries are important, and it can hurt to lose them.</h2>
                    <p className="greetingsText tal fs18">There’s always room for improvement, though! We’ll help you work on it with your growth plan.</p> */}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'your-boundaries':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="52" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(18)}
                    {/* <img src={greetingsBanner7} alt="" className="greetingsBigimage mb16" />
                    <h2 className="greetingsSubtitle small mb8">No matter what, your boundaries are important, and it can hurt to lose them.</h2>
                    <p className="greetingsText tal fs18">There’s always room for improvement, though! We’ll help you work on it with your growth plan.</p> */}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'sex-life':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="53" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">personality</p>
                    <h2 className="greetingsSubtitle small tac mb16">Are you satisfied with your sex life?</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We can adjust your growth plan to help you explore more.</span>
                            <img src={MessageIcon2} alt="" />
                            <p>A regular workout can do wonders for your concentration, memory, and stress levels!</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="sex-life" id="sex-life-1"
                                    onChange={() => handleMessageNextSkip('/greetings/body-and-desires')} />
                                <label htmlFor="sex-life-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="sex-life" id="sex-life-2"
                                    onChange={() => handleMessageNextSkip('/greetings/body-and-desires')} />
                                <label htmlFor="sex-life-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="sex-life" id="sex-life-3"
                                    onChange={() => handleMessageNextSkip('/greetings/body-and-desires')} />
                                <label htmlFor="sex-life-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="sex-life" id="sex-life-4"
                                    onChange={() => handleMessageNextSkip('/greetings/body-and-desires')} />
                                <label htmlFor="sex-life-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="sex-life" id="sex-life-5"
                                    onChange={() => handleMessageNextSkip('/greetings/body-and-desires')} />
                                <label htmlFor="sex-life-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'body-and-desires':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="54" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">personality</p>
                    <h2 className="greetingsSubtitle small tac mb16">Do you want to understand better your body and desires?</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We can adjust your growth plan to help you explore more.</span>
                            <img src={MessageIcon2} alt="" />
                            <p>A regular workout can do wonders for your concentration, memory, and stress levels!</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="body-and-desires" id="body-and-desires-1"
                                    onChange={() => handleMessageNextSkip('/greetings/you-rock')} />
                                <label htmlFor="body-and-desires-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="body-and-desires" id="body-and-desires-2"
                                    onChange={() => handleMessageNextSkip('/greetings/you-rock')} />
                                <label htmlFor="body-and-desires-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="body-and-desires" id="body-and-desires-3"
                                    onChange={() => handleMessageNextSkip('/greetings/you-rock')} />
                                <label htmlFor="body-and-desires-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="body-and-desires" id="body-and-desires-4"
                                    onChange={() => handleMessageNextSkip('/greetings/you-rock')} />
                                <label htmlFor="body-and-desires-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="body-and-desires" id="body-and-desires-5"
                                    onChange={() => handleMessageNextSkip('/greetings/you-rock')} />
                                <label htmlFor="body-and-desires-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'you-rock':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="55" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {/* <img src={greetingsBanner8} alt="" className="greetingsBigimage mb16" />
                    <h2 className="greetingsSubtitle small mb8">You rock!</h2>
                    <p className="greetingsText tal fs18">You’ll breeze through your goals because you’re so determined! Now we’ll assess your personality patterns to better tailor the growth plan.</p> */}
                    {renderBanner(19)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'succeed':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="56" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">patterns</p>
                    <h2 className="greetingsSubtitle small tac mb16">When you succeed in something, you’d rather...</h2>
                    <div className="greetingsRadios mtauto">
                        <div className="greetingsRadio">
                            <input type="radio" name="succeed" id="succeed-1"
                                onChange={() => handleRadioBanner('/greetings/beneficial')} />
                            <label htmlFor="succeed-1">
                                {/* <img src={greetingsSmile4} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">🤔</span>
                                <span>Think of what could be done better</span>
                            </label>
                        </div>
                        <div className="greetingsRadio">
                            <input type="radio" name="succeed" id="succeed-2"
                                onChange={() => handleRadioBanner('/greetings/focusing')} />
                            <label htmlFor="succeed-2">
                                {/* <img src={greetingsSmile5} alt="" /> */}
                                <span className="img" role="img" aria-label="Smiley Emoji">🎉</span>
                                <span>Celebrate the win</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'beneficial':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="57" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(23)}
                    {/* <img src={greetingsBanner8} alt="" className="greetingsBigimage mb16" />
                    <h2 className="greetingsSubtitle small mb8">Focusing on the good parts is an excellent source of motivation.</h2>
                    <p className="greetingsText tal fs18">Your growth plan will help you maintain that positive outlook while seeing room for potential on your road to success.</p> */}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'focusing':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="57" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(20)}
                    {/* <img src={greetingsBanner8} alt="" className="greetingsBigimage mb16" />
                    <h2 className="greetingsSubtitle small mb8">Focusing on the good parts is an excellent source of motivation.</h2>
                    <p className="greetingsText tal fs18">Your growth plan will help you maintain that positive outlook while seeing room for potential on your road to success.</p> */}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'job':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="59" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">personality</p>
                    <h2 className="greetingsSubtitle small tac mb16">Do you like your job, activity?</h2>
                    <div className="greetingsEmojiBox tobott">
                        <div className={`greetingsMessage ${(message === '1') ? 'active' : ''}`}>
                            <span>Don’t worry. Lots of other people are in the same boat.</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">🔋</span>
                            <p>According to our users’ answers, burnout and lack of motivation are the top reasons. We’ll help you find yours and improve your life!</p>
                        </div>
                        <div className={`greetingsMessage ${(message === '2') ? 'active' : ''}`}>
                            <span>Considering a change, perhaps? </span>
                            <span className="img" role="img" aria-label="Smiley Emoji">👐</span>
                            <p>Switching can be challenging, but it’s always possible for a person with a plan. We’ll help you focus on your growth areas to make a real change. </p>
                        </div>
                        <div className={`greetingsMessage ${(message === '3') ? 'active' : ''}`}>
                            <span>It’s great your pursuing your passion!</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">🎯</span>
                            <p>Join millions of FeelGrow users who have discovered a better way to boost their life in just a few minutes a day.</p>
                        </div>
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-1"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="need-to-boost-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-2"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="need-to-boost-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-3"
                                    onChange={() => handleMessage('2')} />
                                <label htmlFor="need-to-boost-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-4"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="need-to-boost-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-5"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="need-to-boost-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'work-life-balance':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="61" status="second" />
                    <p className="greetingsText fw600 orange ttu mb24">personality</p>
                    <h2 className="greetingsSubtitle small tac mb16">Do you maintain a healthy work-life balance?</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We can adjust your growth plan to help you explore more.</span>
                            <img src={MessageIcon2} alt="" />
                            <p>A regular workout can do wonders for your concentration, memory, and stress levels!</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-1"
                                    onChange={() => handleMessageNextSkip('/greetings/healthy-wlb')} />
                                <label htmlFor="tend-to-lose-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-2"
                                    onChange={() => handleMessageNextSkip('/greetings/healthy-wlb')} />
                                <label htmlFor="tend-to-lose-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-3"
                                    onChange={() => handleMessageNextSkip('/greetings/glad-to-hear')} />
                                <label htmlFor="tend-to-lose-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-4"
                                    onChange={() => handleMessageNextSkip('/greetings/glad-to-hear')} />
                                <label htmlFor="tend-to-lose-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-5"
                                    onChange={() => handleMessageNextSkip('/greetings/glad-to-hear')} />
                                <label htmlFor="tend-to-lose-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'healthy-wlb':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="63" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(13)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'glad-to-hear':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="63" status="second" />
                    <p className="greetingsText fw600 orange ttu mb16">personality</p>
                    {renderBanner(14)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'capable':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="68" status="third" />
                    <p className="greetingsText fw600 orange ttu mb24">patterns</p>
                    <h2 className="greetingsSubtitle small tac mb16">I feel like I’m capable of much more professionally</h2>
                    <div className="greetingsEmojiBox tobott">
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-1"
                                    onChange={() => handleMessageNextSkip('/greetings/finances')} />
                                <label htmlFor="need-to-boost-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-2"
                                    onChange={() => handleMessageNextSkip('/greetings/finances')} />
                                <label htmlFor="need-to-boost-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-3"
                                    onChange={() => handleMessageNextSkip('/greetings/finances')} />
                                <label htmlFor="need-to-boost-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-4"
                                    onChange={() => handleMessageNextSkip('/greetings/finances')} />
                                <label htmlFor="need-to-boost-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-5"
                                    onChange={() => handleMessageNextSkip('/greetings/finances')} />
                                <label htmlFor="need-to-boost-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'finances':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="70" status="third" />
                    <p className="greetingsText fw600 orange ttu mb24">patterns</p>
                    <h2 className="greetingsSubtitle small tac mb16">Do you feel good about your finances?</h2>
                    <div className="greetingsEmojiBox tobott">
                        <div className={`greetingsMessage ${(message === '1') ? 'active' : ''}`}>
                            <span>Thanks for sharing. We appreciate your honesty!</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">🌎</span>
                            <p>quiz world's top you'll access insights from the After the bestsellers on financial management, entrepreneurship, and handling money.</p>
                        </div>
                        <div className={`greetingsMessage ${(message === '3') ? 'active' : ''}`}>
                            <span>Happy to hear it! </span>
                            <span className="img" role="img" aria-label="Smiley Emoji">💪</span>
                            <p>Based on your answers, let's focus on other essential things in your growth plan.</p>
                        </div>
                        <div className={`greetingsMessage ${(message === '2') ? 'active' : ''}`}>
                            <span>Not sure how to begin improving your finances?</span>
                            <span className="img" role="img" aria-label="Smiley Emoji">💸</span>
                            <p>they found relevant content and hands-on 97% of our users say financial management and entrepreneurship tips on the FeelGrow app.</p>
                        </div>
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-1"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="need-to-boost-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-2"
                                    onChange={() => handleMessage('1')} />
                                <label htmlFor="need-to-boost-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-3"
                                    onChange={() => handleMessage('2')} />
                                <label htmlFor="need-to-boost-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-4"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="need-to-boost-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="need-to-boost" id="need-to-boost-5"
                                    onChange={() => handleMessage('3')} />
                                <label htmlFor="need-to-boost-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'often-worry':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="75" status="third" />
                    <p className="greetingsText fw600 orange ttu mb24">patterns</p>
                    <h2 className="greetingsSubtitle small tac mb16">Do you often worry that you’re not good enough?</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We can adjust your growth plan to help you explore more.</span>
                            <img src={MessageIcon2} alt="" />
                            <p>A regular workout can do wonders for your concentration, memory, and stress levels!</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-1"
                                    onChange={() => handleMessageNextSkip('/greetings/confidence')} />
                                <label htmlFor="tend-to-lose-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-2"
                                    onChange={() => handleMessageNextSkip('/greetings/confidence')} />
                                <label htmlFor="tend-to-lose-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-3"
                                    onChange={() => handleMessageNextSkip('/greetings/very-common')} />
                                <label htmlFor="tend-to-lose-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-4"
                                    onChange={() => handleMessageNextSkip('/greetings/very-common')} />
                                <label htmlFor="tend-to-lose-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="tend-to-lose" id="tend-to-lose-5"
                                    onChange={() => handleMessageNextSkip('/greetings/very-common')} />
                                <label htmlFor="tend-to-lose-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'confidence':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="80" status="third" />
                    <p className="greetingsText fw600 orange ttu mb16">patterns</p>
                    {renderBanner(15)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'very-common':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="80" status="third" />
                    <p className="greetingsText fw600 orange ttu mb16">patterns</p>
                    {renderBanner(16)}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'compare-yourself':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="85" status="third" />
                    <p className="greetingsText fw600 orange ttu mb24">patterns</p>
                    <h2 className="greetingsSubtitle small tac mb16">Do you often compare yourself to others?</h2>
                    <div className="greetingsEmojiBox tobott">
                        {/* <div className={`greetingsMessage ${message1 ? 'active' : ''}`}>
                            <span>We can adjust your growth plan to help you explore more.</span>
                            <img src={MessageIcon2} alt="" />
                            <p>A regular workout can do wonders for your concentration, memory, and stress levels!</p>
                        </div> */}
                        <div className="greetingsCheckboxes withEmoji">
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="compare-yourself" id="compare-yourself-1"
                                    onChange={() => handleMessageNextSkip('/greetings/inspire-us')} />
                                <label htmlFor="compare-yourself-1">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="compare-yourself" id="compare-yourself-2"
                                    onChange={() => handleMessageNextSkip('/greetings/inspire-us')} />
                                <label htmlFor="compare-yourself-2">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👎</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="compare-yourself" id="compare-yourself-3"
                                    onChange={() => handleMessageNextSkip('/greetings/inspire-us')} />
                                <label htmlFor="compare-yourself-3">
                                    <span className="img" role="img" aria-label="Smiley Emoji">🤷‍♀️</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="compare-yourself" id="compare-yourself-4"
                                    onChange={() => handleMessageNextSkip('/greetings/inspire-us')} />
                                <label htmlFor="compare-yourself-4">
                                    <span className="img small" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                            <div className="greetingsCheckboxEmoji">
                                <input type="radio" name="compare-yourself" id="compare-yourself-5"
                                    onChange={() => handleMessageNextSkip('/greetings/inspire-us')} />
                                <label htmlFor="compare-yourself-5">
                                    <span className="img" role="img" aria-label="Smiley Emoji">👍</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtnBanner()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'inspire-us':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type2" />
                    <GreetingsProgress width="90" status="third" />
                    <p className="greetingsText fw600 orange ttu mb16">patterns</p>
                    {renderBanner(21)}
                    {/* <img src={greetingsBanner9} alt="" className="greetingsBigimage mb16" />
                    <h2 className="greetingsSubtitle small mb8">Your sincerity inspires us!</h2>
                    <p className="greetingsText tal fs18">Knowing your patterns is crucial to crafting a precise growth plan for you. We also wonder about your reading preferences! It will help us recommend the most relevant content to you.</p> */}
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'book-1':
                return <div className={`greetingsItem flex pb24 ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type3" />
                    <h2 className="greetingsSubtitle mb24 tac">Does this course seem interesting to you?</h2>
                    <div className="greetingsBook">
                        <img src={greetingsBook1} onLoad={handleImageLoad} alt="" />
                    </div>
                    <div className="greetingsBookRadios mtbott">
                        <div className="greetingsBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-1" />
                            <label htmlFor="book-1-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="greetingsBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-1-2" />
                            <label htmlFor="book-1-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                </div>;
            case 'book-2':
                return <div className={`greetingsItem flex pb24 ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type3" />
                    <h2 className="greetingsSubtitle mb24 tac">Does this course seem interesting to you?</h2>
                    <div className="greetingsBook">
                        <img src={greetingsBook2} onLoad={handleImageLoad} alt="" />
                    </div>
                    <div className="greetingsBookRadios mtbott">
                        <div className="greetingsBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-2-1" />
                            <label htmlFor="book-2-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="greetingsBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-2-2" />
                            <label htmlFor="book-2-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                </div>;
            case 'book-3':
                return <div className={`greetingsItem flex pb24 ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type3" />
                    <h2 className="greetingsSubtitle mb24 tac">Does this book seem interesting to you?</h2>
                    <div className="greetingsBook">
                        <img src={greetingsBook3} onLoad={handleImageLoad} alt="" />
                    </div>
                    <div className="greetingsBookRadios mtbott">
                        <div className="greetingsBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-3-1" />
                            <label htmlFor="book-3-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="greetingsBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-3-2" />
                            <label htmlFor="book-3-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                </div>;
            case 'book-4':
                return <div className={`greetingsItem flex pb24 ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type3" />
                    <h2 className="greetingsSubtitle mb24 tac">Does this book seem interesting to you?</h2>
                    <div className="greetingsBook">
                        <img src={greetingsBook4} onLoad={handleImageLoad} alt="" />
                    </div>
                    <div className="greetingsBookRadios mtbott">
                        <div className="greetingsBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-4-1" />
                            <label htmlFor="book-4-1">
                                <img src={greetingsNo} alt="" />
                                <span>No</span>
                            </label>
                        </div>
                        <div className="greetingsBookRadio" onClick={() => handleNextBtn()}>
                            <input type="radio" id="book-4-2" />
                            <label htmlFor="book-4-2">
                                <img src={greetingsYes} alt="" />
                                <span>Yes</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                </div>;
            case 'great-choise':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''} pb100`}>
                    <GreetingsHeader type="type3" />
                    {/* <p className="greetingsText fw600 orange ttu mb16">personality</p> */}
                    {/* <img src={greetingsBanner9} alt="" className="greetingsBigimage mb16" />
                    <h2 className="greetingsSubtitle small mb8">Great choices, ace! We’re almost done.</h2>
                    <p className="greetingsText tal fs18">Thanks for your impressive sincerity. We’ve almost done setting up your roadmap. A little patience, fellow!</p> */}
                    {renderBanner(22)}
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'self-growth':
                return <div className="greetingsItem flex visible">
                    <GreetingsHeader type="type3" />
                    <h2 className="greetingsSubtitle mb16 tac">Do you have a specific reason for self-growth?</h2>
                    <div className="greetingsCheckboxes">
                        <div className="greetingsCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-1"
                                onChange={() => handleCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '1')} />
                            <label htmlFor="self-growth-1">
                                {/* <span className="greetingsCheckboxImgEmojiIcon">
                                    <img src={EmojiFace4} alt="" />
                                </span> */}
                                <span className="greetingsCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">👩</span>
                                <span className="greetingsCheckboxImageText">Getting a promotion</span>
                            </label>
                        </div>
                        <div className="greetingsCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-2"
                                onChange={() => handleCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '2')} />
                            <label htmlFor="self-growth-2">
                                {/* <span className="greetingsCheckboxImgEmojiIcon">
                                    <img src={EmojiFace5} alt="" />
                                </span> */}
                                <span className="greetingsCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">📊</span>
                                <span className="greetingsCheckboxImageText">Becoming an entrepreneur</span>
                            </label>
                        </div>
                        <div className="greetingsCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-3"
                                onChange={() => handleCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '3')} />
                            <label htmlFor="self-growth-3">
                                {/* <span className="greetingsCheckboxImgEmojiIcon">
                                    <img src={EmojiFace6} alt="" />
                                </span> */}
                                <span className="greetingsCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">💏</span>
                                <span className="greetingsCheckboxImageText">Relationship commitment</span>
                            </label>
                        </div>
                        <div className="greetingsCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-4"
                                onChange={() => handleCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '4')} />
                            <label htmlFor="self-growth-4">
                                {/* <span className="greetingsCheckboxImgEmojiIcon">
                                    <img src={EmojiFace7} alt="" />
                                </span> */}
                                <span className="greetingsCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">👨‍👩‍👧‍👦</span>
                                <span className="greetingsCheckboxImageText">Parenthood</span>
                            </label>
                        </div>
                        <div className="greetingsCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-5"
                                onChange={() => handleCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '5')} />
                            <label htmlFor="self-growth-5">
                                {/* <span className="greetingsCheckboxImgEmojiIcon">
                                    <img src={EmojiFace8} alt="" />
                                </span> */}
                                <span className="greetingsCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">✈️</span>
                                <span className="greetingsCheckboxImageText">Major life transition</span>
                            </label>
                        </div>
                        <div className="greetingsCheckboxImgEmoji">
                            <input type="checkbox" id="self-growth-6"
                                onChange={() => handleCheckedCB(checkedSeflGrowthCB, setCheckedSeflGrowthCB,  '6')} />
                            <label htmlFor="self-growth-6">
                                {/* <span className="greetingsCheckboxImgEmojiIcon">
                                    <img src={EmojiFace9} alt="" />
                                </span> */}
                                <span className="greetingsCheckboxImgEmojiIcon" role="img" aria-label="Smiley Emoji">🤯</span>
                                <span className="greetingsCheckboxImageText">Mental and emotion well being</span>
                            </label>
                        </div>
                    </div>
                    <div className="greetingsBtnImit"></div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'set-goal':
                return <div className="greetingsItem visible">
                    <GreetingsHeader type="type3" />
                    <h2 className="greetingsSubtitle mb16 tac">Set your goal on timing</h2>
                    <p className="greetingsText fs18">Pick the amount of time you will spend on self-development daily</p>
                    <div className="greetingsCheckboxes">
                        <div className="greetingsRadioEmoji">
                            <input type="radio" name="set-goal" id="set-goal-1"
                                onChange={() => handleRadio()} />
                            <label htmlFor="set-goal-1">
                                {/* <span className="greetingsRadioEmojiIcon">
                                    <img src={EmojiFace10} alt="" />
                                </span> */}
                                <span className="greetingsRadioEmojiIcon" role="img" aria-label="Smiley Emoji">🤙</span>
                                <span className="greetingsRadioEmojiText">Easy</span>
                                <span className="greetingsRadioEmojiTime">5 min/day</span>
                            </label>
                        </div>
                        <div className="greetingsRadioEmoji">
                            <input type="radio" name="set-goal" id="set-goal-2"
                                onChange={() => handleRadio()} />
                            <label htmlFor="set-goal-2">
                                {/* <span className="greetingsRadioEmojiIcon">
                                    <img src={EmojiFace11} alt="" />
                                </span> */}
                                <span className="greetingsRadioEmojiIcon" role="img" aria-label="Smiley Emoji">👌</span>
                                <span className="greetingsRadioEmojiText">Common</span>
                                <span className="greetingsRadioEmojiTime">10 min/day</span>
                            </label>
                        </div>
                        <div className="greetingsRadioEmoji">
                            <input type="radio" name="set-goal" id="set-goal-3"
                                onChange={() => handleRadio()} />
                            <label htmlFor="set-goal-3">
                                {/* <span className="greetingsRadioEmojiIcon">
                                    <img src={EmojiFace12} alt="" />
                                </span> */}
                                <span className="greetingsRadioEmojiIcon" role="img" aria-label="Smiley Emoji">🤟</span>
                                <span className="greetingsRadioEmojiText">Serious</span>
                                <span className="greetingsRadioEmojiTime">15 min/day</span>
                            </label>
                        </div>
                        <div className="greetingsRadioEmoji">
                            <input type="radio" name="set-goal" id="set-goal-4"
                                onChange={() => handleRadio()} />
                            <label htmlFor="set-goal-4">
                                {/* <span className="greetingsRadioEmojiIcon">
                                    <img src={EmojiFace13} alt="" />
                                </span> */}
                                <span className="greetingsRadioEmojiIcon" role="img" aria-label="Smiley Emoji">✊</span>
                                <span className="greetingsRadioEmojiText">Intensive</span>
                                <span className="greetingsRadioEmojiTime">20 min/day</span>
                            </label>
                        </div>
                    </div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus} >Continue</button>
                </div>;
            case 'learning-experience':
                return <div className="greetingsItem visible">
                    <GreetingsHeader type="type3" />
                    <h2 className="greetingsSubtitle mb16 tac">
                        We are crafting your <br />
                        <span>learning experience...</span>
                    </h2>
                    <div className="greetingsProgress">
                        <div className={`greetingsProgressItem ${(progress1 === 100) && 'active'}`}>
                            <span className="greetingsProgressText">
                                {(progress1 === 100) ? 'Goals' : 'Setting goals'}
                            </span>
                            <span className="greetingsProgressNumber">{progress1}%</span>
                            <img src={greetingsDone} alt="" className="greetingsProgressCheck" />
                            <div className="greetingsProgressLine">
                                <span style={{width: `${progress1}%`}}></span>
                            </div>
                        </div>
                        <div className={`greetingsProgressItem ${(progress2 === 100) && 'active'} ${(progress1 === 100) ? '' : 'hidden'}`}>
                            <span className="greetingsProgressText">
                                {(progress1 === 100) ? 'Growth areas' : 'Adapting growth areas'}
                            </span>
                            <span className="greetingsProgressNumber">{progress2}%</span>
                            <img src={greetingsDone} alt="" className="greetingsProgressCheck" />
                            <div className="greetingsProgressLine">
                                <span style={{width: `${progress2}%`}}></span>
                            </div>
                        </div>
                        <div className={`greetingsProgressItem ${(progress3 === 100) && 'active'} ${(progress2 === 100) ? '' : 'hidden'}`}>
                            <span className="greetingsProgressText">
                                {(progress1 === 100) ? 'Content' : 'Picking content'}
                            </span>
                            <span className="greetingsProgressNumber">{progress3}%</span>
                            <img src={greetingsDone} alt="" className="greetingsProgressCheck" />
                            <div className="greetingsProgressLine">
                                <span style={{width: `${progress3}%`}}></span>
                            </div>
                        </div>
                        <div className={`greetingsProgressItem ${(progress4 === 100) && 'active'} ${(progress3 === 100) ? '' : 'hidden'}`}>
                            <span className="greetingsProgressText">Chellenges</span>
                            <span className="greetingsProgressNumber">{progress4}%</span>
                            <img src={greetingsDone} alt="" className="greetingsProgressCheck" />
                            <div className="greetingsProgressLine">
                                <span style={{width: `${progress4}%`}}></span>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsReview">
                        <p className="greetingsReviewTitle">Enjoyed by 11K+ people</p>
                        <div className="greetingsReviewItem">
                            <div className="greetingsReviewStars">
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                            </div>
                            <span className="greetingsReviewName">by LisJehfb</span>
                            <p className="greetingsReviewText">Devoting <b>15 min a day</b> to learning to <b>know yourself</b> trying things differently, and <b>applying</b> them to your <b>life</b> will bring you an <b>improved</b> person.</p>
                        </div>
                    </div>
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>

                    <div className={`greetingsModal ${modalStatus1 && 'active'}`}>
                        {/* <div className="greetingsModalOverlay"></div> */}
                        <div className="greetingsModalInfo">
                            <p>To move forward, specify</p>
                            <h2>Do you self-reflect?</h2>
                            <div className="greetingsModalAnswers">
                                <button onClick={() => handleModalNext1()}>No</button>
                                <button onClick={() => handleModalNext1()}>Yes</button>
                            </div>
                        </div>
                    </div>

                    <div className={`greetingsModal ${modalStatus2 && 'active'}`}>
                        {/* <div className="greetingsModalOverlay"></div> */}
                        <div className="greetingsModalInfo">
                            <p>To move forward, specify</p>
                            <h2>Do you want to give up when things get hard?</h2>
                            <div className="greetingsModalAnswers">
                                <button onClick={() => handleModalNext2()}>No</button>
                                <button onClick={() => handleModalNext2()}>Yes</button>
                            </div>
                        </div>
                    </div>

                    <div className={`greetingsModal ${modalStatus3 && 'active'}`}>
                        {/* <div className="greetingsModalOverlay"></div> */}
                        <div className="greetingsModalInfo">
                            <p>To move forward, specify</p>
                            <h2>Are you inclined to finish what you start?</h2>
                            <div className="greetingsModalAnswers">
                                <button onClick={() => handleModalNext3()}>No</button>
                                <button onClick={() => handleModalNext3()}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>;
            case 'email':
                return <div className="greetingsItem visible">
                    <GreetingsHeader type="type4" />
                    <h2 className="greetingsSubtitle mb16 tac">
                        <span>Achieve your goal</span> with <br /> FeelGrow
                    </h2>
                    <form className="greetingsForm">
                        <div className="greetingsInput">
                            <img src={greetingsEnvelope} alt="" />
                            <input type="text"
                                onChange={(e) => valideOnKeyup(e.target.value)} 
                                placeholder="Email" />
                        </div>
                        <div className="greetingsRule">
                            <input type="checkbox" id="rule-1" />
                            <label htmlFor="rule-1">I agree to receive the latest FeelGrow news and updates via email </label>
                        </div>
                        <div className="greetingsRule">
                            <input type="checkbox" id="rule-2"
                                onChange={() => setPrivacyStatus(!privacyStatus)} />
                            <label htmlFor="rule-2">I agree to FeelGrow <a href="/terms" target="_blank">Terms & Use</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.</label>
                        </div>
                    </form>
                    <button onClick={() => submitEmailForm()} className="greetingsNextBtn fixed"
                        disabled={nextBtnStatus}>Continue</button>
                </div>;
            case 'plan-is-ready':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type4" />
                    <div className="greetingsReady">
                        <h2 className="greetingsSubtitle mb8 tac">
                            Your personal growth plan <span>is ready</span>
                        </h2>
                        <p className="greetingsText fs18">Based on your answers, we’ve crafted a self-growth plan</p>
                        {/* <img src={greetingsGraph} alt="" className="greetingsBigimage mb24" /> */}
                        <div className="greetingsBigimage mb24">
                            <span className="pbi56"></span>
                            <img src={greetingsGraph} onLoad={handleImageLoad} alt="" />
                        </div>
                        <div className="greetingsDates">
                            <span>{currentDate}</span>
                            <span>{dateIn15Days}</span>
                        </div>
                        <div className="greetingsReadyApps">
                            <div className="greetingsReadyAppsIcons">
                                <img src={greetingsApple} alt="" />
                                <img src={greetingsAndroid} alt="" />
                            </div>
                            <p className="greetingsReadyAppsText">To use the app you’ll need an iPhone or Android smartphone</p>
                        </div>
                    </div>
                    
                    <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button>
                </div>;
            case 'choose-plan':
                return <div className={`greetingsItem ${imageLoaded ? 'visible' : ''}`}>
                    <GreetingsHeader type="type1" />
                    <div className="greetingsTimer">
                        <div className="greetingsTimerText">
                            <p>33% discount is reserved for:</p>
                            <span>9:58</span>
                        </div>
                        <button className="greetingsTimerBtn">Continue</button>
                    </div>
                    <div className="greetingsFinal">
                        <h2 className="greetingsSubtitle mb8 tac ph16">
                            Become the most interesting person in the room
                        </h2>
                        <p className="greetingsText fs18">Join over 11 239 people in the FeelGrow community</p>
                        <div className="greetingsFinalDiscount">
                            <div className="greetingsFinalDiscountTop">
                                <span>33% discount is reserved for 9:58</span>
                            </div>
                            {/* <img src={greetingsMind} alt="" className="greetingsBigimage" /> */}
                            <div className="greetingsBigimage">
                                <span className="pbi93"></span>
                                <img src={greetingsMind} onLoad={handleImageLoad} alt="" />
                            </div>
                            <div className="greetingsFinalDiscountBott">
                                <p>78 people learned self-growth insights last hour</p>
                                <div className="greetingsFinalDiscountMails">
                                    <span>jesic***6 insights 12 minutes</span>
                                    <span>ali.k***12 insights 16</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsPlans">
                        <h2 className="greetingsTitle">Choose your plan </h2>
                        <div className="greetingsPlan">
                            <div className="greetingsPlanInside">
                                <input type="radio" name="plan" id="plan-1"
                                    checked={(activeTarif === '1' ? true : false)}
                                    onChange={() => handleTarif('1')} />
                                <label htmlFor="plan-1">
                                    <span className="greetingsPlanFull">
                                        <b>1 month</b>
                                        <del>$16.00</del>
                                        <span>$10.72</span>
                                    </span>
                                    <div className="greetingsPlanNext">
                                        <del>$0.53</del>
                                        <b>0</b>
                                        <span>
                                            <i>36</i> <br /> per day
                                        </span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="greetingsPlan">
                            <div className="greetingsPlanInside">
                                <input type="radio" name="plan" id="plan-2"
                                    checked={(activeTarif === '2' ? true : false)}
                                    onChange={() => handleTarif('2')} />
                                <label htmlFor="plan-2" className="spec">
                                    <span className="greetingsPlanRemark">Most popular</span>
                                    <span className="greetingsPlanFull">
                                        <b>3 month</b>
                                        <del>$29.11</del>
                                        <span>$19.50</span>
                                    </span>
                                    <div className="greetingsPlanNext">
                                        <del>$0.32</del>
                                        <b>0</b>
                                        <span>
                                            <i>22</i> <br /> per day
                                        </span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="greetingsPlan">
                            <div className="greetingsPlanInside">
                                <input type="radio" name="plan" id="plan-3"
                                    checked={(activeTarif === '3' ? true : false)}
                                    onChange={() => handleTarif('3')} />
                                <label htmlFor="plan-3">
                                    
                                    <span className="greetingsPlanFull">
                                        <b>6 month</b>
                                        <del>$50.95</del>
                                        <span>$34.14</span>
                                    </span>
                                    <div className="greetingsPlanNext">
                                        <del>$0.28</del>
                                        <b>0</b>
                                        <span>
                                            <i>19</i> <br /> per day
                                        </span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        {renderRenewPrice()}
                    </div>
                    <div className="greetingsBuy">
                        <button className="greetingsNextBtn" onClick={() => handleNextBtn()}>Continue</button>
                        <div className="greetingsBuySecure">
                            <span>
                                <img src={greetingsSecure} alt="" />
                                <b>Pay safe & secure</b>
                            </span>
                        </div>
                        <div className="greetingsBuyMethod">
                            <img src={greetingsPay1} alt="" />
                            <img src={greetingsPay2} alt="" />
                            <img src={greetingsPay3} alt="" />
                            <img src={greetingsPay4} alt="" />
                            <img src={greetingsPay5} alt="" />
                            <img src={greetingsPay6} alt="" />
                            <img src={greetingsPay7} alt="" />
                            <img src={greetingsPay8} alt="" />
                        </div>
                    </div>
                    <div className="greetingsAdv">
                        <h2 className="greetingsSubtitle tac mb16">What you get with FeelGrow</h2>
                        <div className="greetingsAdvItem">
                            <img src={advaStar} alt="" />
                            <p>1000+ non-fiction book summaries</p>
                        </div>
                        <div className="greetingsAdvItem">
                            <img src={advaStar} alt="" />
                            <p>5000+ life-changing insights</p>
                        </div>
                        <div className="greetingsAdvItem">
                            <img src={advaStar} alt="" />
                            <p>Habit tracker for seeing your progress</p>
                        </div>
                        <div className="greetingsAdvItem">
                            <img src={advaStar} alt="" />
                            <p>Self-growth challenges for any goals</p>
                        </div>
                        <div className="greetingsAdvItem">
                            <img src={advaStar} alt="" />
                            <p>Save & memorize favorite book insights with the Repetition feature</p>
                        </div>
                    </div>
                    <div className="greetingsFaq">
                        <h2 className="greetingsSubtitle tac mb16">We are ready to answer your questions</h2>

                        {
                            faqList.map((data) => (
                                <GreetingsFaqItem data={data} />
                            ))
                        }

                    </div>
                    <div className="greetingsComments">
                        <h2 className="greetingsSubtitle tac mb16">People love the FeelGrow app</h2>
                        <p className="greetingsText mb16">Become a member of our global community of <span className="purple">11 thousand people</span></p>
                        <div className="greetingsComment">
                            <div className="greetingsReviewStars">
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                            </div>
                            <span className="greetingsReviewName">by LisJehfb</span>
                            <p className="greetingsReviewText">Devoting <b>15 min a day</b> to learning to <b>know yourself</b> trying things differently, and <b>applying</b> them to your <b>life</b> will bring you an <b>improved</b> person.</p>
                            <div className="greetingsCommentUser">
                                <div className="greetingsCommentUserIcon">
                                    <img src={greetingsComment1} alt="" />
                                </div>
                                <span>Emmet Finch</span>
                            </div>
                        </div>
                        <div className="greetingsComment">
                            <div className="greetingsReviewStars">
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                            </div>
                            <span className="greetingsReviewName">by LisJehfb</span>
                            <p className="greetingsReviewText">Devoting <b>15 min a day</b> to learning to <b>know yourself</b> trying things differently, and <b>applying</b> them to your <b>life</b> will bring you an <b>improved</b> person.</p>
                            <div className="greetingsCommentUser">
                                <div className="greetingsCommentUserIcon">
                                    <img src={greetingsComment1} alt="" />
                                </div>
                                <span>Emmet Finch</span>
                            </div>
                        </div>
                        <div className="greetingsComment">
                            <div className="greetingsReviewStars">
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                                <img src={greetingsStar} alt="" />
                            </div>
                            <span className="greetingsReviewName">by LisJehfb</span>
                            <p className="greetingsReviewText">Devoting <b>15 min a day</b> to learning to <b>know yourself</b> trying things differently, and <b>applying</b> them to your <b>life</b> will bring you an <b>improved</b> person.</p>
                            <div className="greetingsCommentUser">
                                <div className="greetingsCommentUserIcon">
                                    <img src={greetingsComment1} alt="" />
                                </div>
                                <span>Emmet Finch</span>
                            </div>
                        </div>
                        <div className="greetingsRating">
                            <div className="greetingsRatingItem">
                                <span className="greetingsRatingNumber">4.7</span>
                                <img src={greetingsGplay} alt="" className="greetingsRatingIcon" />
                                <div className="greetingsRatingStars">
                                    <img src={greetingsStar} alt="" />
                                    <img src={greetingsStar} alt="" />
                                    <img src={greetingsStar} alt="" />
                                    <img src={greetingsStar} alt="" />
                                    <img src={greetingsStar} alt="" />
                                </div>
                                <p className="greetingsRatingText">5K ratings</p>
                            </div>
                            <div className="greetingsRatingItem">
                                <span className="greetingsRatingNumber">4.7</span>
                                <img src={greetingsAppstore} alt="" className="greetingsRatingIcon" />
                                <div className="greetingsRatingStars">
                                    <img src={greetingsStar} alt="" />
                                    <img src={greetingsStar} alt="" />
                                    <img src={greetingsStar} alt="" />
                                    <img src={greetingsStar} alt="" />
                                    <img src={greetingsStar} alt="" />
                                </div>
                                <p className="greetingsRatingText">10K ratings</p>
                            </div>
                        </div>
                    </div>
                    <div className="greetingsPlans">
                        <h2 className="greetingsTitle">Choose your plan </h2>
                        <div className="greetingsPlan">
                            <div className="greetingsPlanInside">
                                <input type="radio" name="plan-2" id="plan-4"
                                    checked={(activeTarif === '1' ? true : false)}
                                    onChange={() => handleTarif('1')} />
                                <label htmlFor="plan-4">
                                    <span className="greetingsPlanFull">
                                        <b>1 month</b>
                                        <del>$16.00</del>
                                        <span>$10.72</span>
                                    </span>
                                    <div className="greetingsPlanNext">
                                        <del>$0.53</del>
                                        <b>0</b>
                                        <span>
                                            <i>36</i> <br /> per day
                                        </span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="greetingsPlan">
                            <div className="greetingsPlanInside">
                                <input type="radio" name="plan-2" id="plan-5"
                                    checked={(activeTarif === '2' ? true : false)}
                                    onChange={() => handleTarif('2')} />
                                <label htmlFor="plan-5" className="spec">
                                    <span className="greetingsPlanRemark">Most popular</span>
                                    <span className="greetingsPlanFull">
                                        <b>3 month</b>
                                        <del>$29.11</del>
                                        <span>$19.50</span>
                                    </span>
                                    <div className="greetingsPlanNext">
                                        <del>$0.32</del>
                                        <b>0</b>
                                        <span>
                                            <i>22</i> <br /> per day
                                        </span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="greetingsPlan">
                            <div className="greetingsPlanInside">
                                <input type="radio" name="plan-2" id="plan-6"
                                    checked={(activeTarif === '3' ? true : false)}
                                    onChange={() => handleTarif('3')} />
                                <label htmlFor="plan-6">
                                    
                                    <span className="greetingsPlanFull">
                                        <b>6 month</b>
                                        <del>$50.95</del>
                                        <span>$34.14</span>
                                    </span>
                                    <div className="greetingsPlanNext">
                                        <del>$0.28</del>
                                        <b>0</b>
                                        <span>
                                            <i>19</i> <br /> per day
                                        </span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        {renderRenewPrice()}
                    </div>
                    <div className="greetingsBuy">
                        <button className="greetingsNextBtn" onClick={() => handleNextBtn()}>Continue</button>
                        <div className="greetingsBuySecure">
                            <span>
                                <img src={greetingsSecure} alt="" />
                                <b>Pay safe & secure</b>
                            </span>
                        </div>
                        <div className="greetingsBuyMethod">
                            <img src={greetingsPay1} alt="" />
                            <img src={greetingsPay2} alt="" />
                            <img src={greetingsPay3} alt="" />
                            <img src={greetingsPay4} alt="" />
                            <img src={greetingsPay5} alt="" />
                            <img src={greetingsPay6} alt="" />
                            <img src={greetingsPay7} alt="" />
                            <img src={greetingsPay8} alt="" />
                        </div>
                    </div>
                    
                    
                    {/* <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button> */}
                </div>;
            case 'payment':
                return <div className="greetingsItem visible">
                    <div className="greetingsPayment">
                        <p className="greetingsPaymentTitle">Pay fast & secure with</p>
                        <p className="greetingsPaymentText"><span>91% of users</span> stay with us after the initial 30-day subscription</p>
                        <div className="greetingsPaymentPrices">
                            <span className="greetingsPaymentTotal">Total:</span>
                            {renderPrice()}
                        </div>
                        <div className="greetingsPaymentMethods">
                            <img src={greetingsPay4} alt="" />
                            <img src={greetingsPay5} alt="" />
                            <img src={greetingsPay6} alt="" />
                            <img src={greetingsPay7} alt="" />
                            <img src={greetingsPay8} alt="" />
                        </div>
                        <div className="greetingsPaymentCard">
                            <span className="greetingsPaymentCardName">card</span>
                            <div className="greetingsPaymentCardInput">
                                <label htmlFor="card-number">Credit or Debit Card Number</label>
                                <input id="card-number" type="text" placeholder="XXXX XXXX XXXX XXXX" />
                            </div>
                            <div className="greetingsPaymentCardInput small">
                                <label htmlFor="card-date">Expiry Date</label>
                                <input id="card-date" type="text" placeholder="MM/YY" />
                            </div>
                            <div className="greetingsPaymentCardInput small">
                                <label htmlFor="card-code">CVV/CVC</label>
                                <input id="card-code" type="text" placeholder="CVV" />
                            </div>
                        </div>
                        <button className="greetingsPaymentBtn">
                            <img src={greetingsLock} alt="" />
                            <span>Confirm payment</span>
                        </button>
                        <button className="greetingsPaymentApay">
                            <img src={greetingsApay} alt="" />
                        </button>
                        <div className="greetingsBuySecure">
                            <span>
                                <img src={greetingsSecure} alt="" />
                                <b>Pay safe & secure</b>
                            </span>
                        </div>
                        <div className="greetingsPaymentSafe">
                            <img src={greetingsSafe1} alt="" />
                            <img src={greetingsSafe2} alt="" />
                            <img src={greetingsSafe3} alt="" />
                            <img src={greetingsSafe4} alt="" />
                        </div>
                    </div>
                    
                    {/* <button onClick={() => handleNextBtn()} className="greetingsNextBtn fixed">Continue</button> */}
                </div>;
            default:
                return <div>This page doesn`t exist</div>;
        }
    }

    return (
        <>
            <style>
                {`
                    body{
                        min-height: initial;
                    }
                    .greetingsItem, .greetingsItem.flex{
                        min-height: ${blockHeight}px;
                    }
                `}
            </style>

            {renderPage()}

            <ToastContainer/>
        </>
    )
}

export default memo(GreetingsPageItem);
