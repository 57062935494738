import './styles.scss';
import React, {memo, useState} from 'react';
import parse from 'html-react-parser';
import {Link, navigate} from "gatsby";
import {
    greetingsLogo,
    greetingsMenu,
    greetingsBack
} from "../../images";

interface MyComponentProps {
    type: string
}

const GreetingsHeader = ({ type } : MyComponentProps) => {

    const handleBackBtn = () => {
        navigate(-1);
    }

    const renderHeader = () => {
        switch(type) {
            case 'type1':
                return <div className="greetingsHeader">
                    <div className="greetingsHeaderLogo">
                        <img src={greetingsLogo} alt="" />
                    </div>
                    <div className="greetingsHeaderMenu">
                        <img src={greetingsMenu} alt="" />
                    </div>
                </div>;
            case 'type2':
                return <div className="greetingsHeader mb0">
                    <button className="greetingsHeaderBack" onClick={() => handleBackBtn()}>
                        <img src={greetingsBack} alt="" />
                        <span>Back</span>
                    </button>
                    <div className="greetingsHeaderLogo">
                        <img src={greetingsLogo} alt="" />
                    </div>
                    <div className="greetingsHeaderBackEmpty"></div>
                </div>;
            case 'type3':
                return <div className="greetingsHeader">
                    <button className="greetingsHeaderBack" onClick={() => handleBackBtn()}>
                        <img src={greetingsBack} alt="" />
                        <span>Back</span>
                    </button>
                    <div className="greetingsHeaderLogo">
                        <img src={greetingsLogo} alt="" />
                    </div>
                    <div className="greetingsHeaderBackEmpty"></div>
                </div>;
            case 'type4':
                return <div className="greetingsHeader jcc">
                    <div className="greetingsHeaderLogo">
                        <img src={greetingsLogo} alt="" />
                    </div>
                </div>;
            default:
                return <div>This page doesn`t exist</div>;
        }
    }

    return (
        <>
            {renderHeader()}
        </>
    )
}

export default memo(GreetingsHeader);
