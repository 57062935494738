import './styles.scss';
import React, {memo, useState} from 'react';
import parse from 'html-react-parser';
import {Link, navigate} from "gatsby";
import {
    greetingsLogo,
    greetingsMenu,
    greetingsBack
} from "../../images";

interface MyComponentProps {
    width: string,
    status: string
}

const GreetingsProgress = ({ width, status } : MyComponentProps) => {

    // const renderProgress = () => {
    //     switch(type) {
    //         case 'type1':
    //             return <div className="greetingsProgr">
    //                 <div className="greetingsProgrDots">
    //                     <span></span>
    //                     <span></span>
    //                     <span></span>
    //                     <span></span>
    //                 </div>
    //                 <span className="greetingsProgrLine"></span>
    //             </div>;
    //         default:
    //             return <div>This page doesn`t exist</div>;
    //     }
    // }

    return (
        <>
            <div className={`greetingsProgr`}>
                <div className={`greetingsProgrDots ${status}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={`greetingsProgrLine`}>
                    <span style={{width: `${width}%`}}></span>
                </div>
            </div>
        </>
    )
}

export default memo(GreetingsProgress);
